/* eslint-disable */
import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import "./assets/css/app.css";
import { 
  formbuilder
} from "./assets/icons.js";

const currentUrl = window.location.href
let environment = ''
if (currentUrl.includes('development')) {
  environment = '.development'
}
else if (currentUrl.includes('staging')) {
  environment = '.staging'
}
else if (currentUrl.includes('local')) {
  environment = 'local'

}
else {
  environment = ''
}

const data = {
  props: {
    config: {
      api_url: environment === 'local' ? 'http://localhost:5006' : `https://bc-app-service${environment}.app.winedirect.com`
    }
  }
};

// const data = {
//   props: {
//     config: {
//       api_url: 'https://bc-app-service.development.app.winedirect.com'
//     }
//   }
// };


let loaded = false;
let apps = [
  'form-builder',
  'shipping-rates',
];
let iconGrid = ''

window.addEventListener('single-spa:first-mount', () => {
  console.log('single-spa just mounted the very first application');
});

window.addEventListener('single-spa:routing-event', async (evt) => {
  if (!loaded) {
    document.getElementById('sidebar-toggle').addEventListener('click', navView)
    await loadNav()
    await loadIcons()
    document.getElementById('main-loader').style.display = 'none'
    loaded = true;
  }
});

window.addEventListener('single-spa:app-change', evt => {
  console.log(
    'A routing event occurred where at least one application was mounted/unmounted', evt
  );

  const { MOUNTED } = evt.detail.appsByNewStatus
  const getNewApp = MOUNTED[0]
  const newApp = getNewApp ? getNewApp.split('/')[1] : 'home'
  const getLink = document.querySelector(`[data-app="${newApp}"]`)
  if (MOUNTED.length > 0) {
    if (getLink) {
      const activeLinks = document.querySelectorAll('.sidebar-link')
      activeLinks.forEach(link => {
        link.classList.remove('sidebar-selected')
      })
      getLink.classList.add('sidebar-selected')
    }
  }
  else {
    const activeLinks = document.querySelectorAll('.sidebar-link')
    activeLinks.forEach(link => {
      link.classList.remove('sidebar-selected')
    })
    getLink.classList.add('sidebar-selected')

    const iconsElement = document.querySelector('.wd-icons')

    if (iconsElement) {
      document.querySelector('.wd-icons').innerHTML = iconGrid
    }
  }
});

const navView = () => {
  const sidebar = document.querySelector('.wd-sidebar')
  if (sidebar.style.display === 'none') {
    sidebar.style.display = 'block'
  }
  else {
    sidebar.style.display = 'none'
  }
}

const loadNav = async () => {
  return new Promise(async (resolve) => {
    const nav = document.querySelector('.wd-navigation')
    let navHtml = ''
    apps.forEach(app => {
      navHtml += `
        <a data-app="${app}" class="sidebar-link" onclick="singleSpaNavigate(event)" href="/${app}">
          ${app?.replace('-', ' ')}
        </a>
      `
    })
    nav.innerHTML = navHtml
    resolve(true)
  })
}

const loadIcons = async () => {
  return new Promise(async (resolve) => {
    const icons = document.querySelector('.wd-icons')

    if (!icons) {
      return resolve(true)
    }

    let iconHtml = ''
    apps.forEach(app => {
      let icon = ''
      switch (app) {
        case 'form-builder':
          icon = formbuilder
      }
      iconHtml += `
        <a data-app="${app}" class="icon-link" onclick="singleSpaNavigate(event)" href="/${app}">
            ${icon}
            ${app?.replace('-', ' ')}
        </a>
      `
    })
    icons.innerHTML = iconHtml
    iconGrid = iconHtml
    resolve(true)
  })
}



const urlParams = new URLSearchParams(window.location.search);
let url = `${data.props.config.api_url}/api/app/load`
const signedPayload = urlParams.get('signed_payload')
if (signedPayload) {
  url = `${data.props.config.api_url}/api/app/load?signed_payload=${signedPayload}`
}
const getStoreConfig = await fetch(url, {
  credentials: 'include',
}).catch(err => {
  console.log('Could not authenticate user', err)
});

if (getStoreConfig.status !== 200) {
  console.log('Could not authenticate user')
}
else {
  const storeConfig = await getStoreConfig.json()
  const {session_token, active_apps} = storeConfig
  
  data.props.config.session_token = session_token
  apps = apps.concat(active_apps)
  const routes = constructRoutes(microfrontendLayout, data);

  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      const app = name.split('/')[1]
      
      if (apps.includes(app)) {
        return System.import(name)
      } 
      else {
        throw Error('application disabled')
      }
    },
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });

  applications.forEach(registerApplication);
  layoutEngine.activate();
  start();
}

